<template>
	<article class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_winterhealthcare/title.png" alt="冬のココロの健康管理～太陽の光でポジティブに～ presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<section class="lp__intro">
			<h2 class="lp__intro--title"><img src="~@/assets/img/lp_winterhealthcare/habit_subtitle01.png" alt="うつや不眠を予防!! 今すぐ実践したい朝の習慣"></h2>
			<p class="lp__text lp__intro--text">うつ予備軍状態や日照不足による症状を緩和するには、照明器具を使った「光療法」もありますが、まずは太陽をある程度浴びることが大切。特に<em>朝の太陽光は体内時計を整え、気分を高める効果</em>もあります。次の3つのことを朝の習慣に取り入れてみましょう！</p>
		</section>
		<!-- /イントロ -->

		<!-- 朝の習慣1 -->
		<section class="lp__contents is-habit1" id="js_habit1">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/habit01.png" alt="朝の習慣1"></span>
				<p>起きたらすぐ<br>カーテンを開ける</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__contents--img is-small"><img src="~@/assets/img/lp_winterhealthcare/habit_img01.png" alt="カーテンを開けるイメージ"></p>
			<p class="lp__text lp__contents--text">朝起きたらすぐにカーテンを開けて、朝の太陽光を部屋に取り込んで過ごすことで気分を高め、体内時計を整える効果があります。</p>
			<p class="lp__contents--img"><img src="~@/assets/img/lp_winterhealthcare/habit_img02.png" alt="太陽高度の変化(東京)"></p>
			<p class="lp__text lp__contents--text">冬の太陽は、高度が低くなるため、<em>部屋の奥深くまで日ざしが届くメリット</em>があります。晩秋から初冬の穏やかに晴れた日のことを”小春日和”といいます。小春日和には<em>お部屋に太陽の光を取り込んで過ごす</em>と良さそうです。</p>
		</section>
		<!-- /朝の習慣1 -->

		<!-- 朝の習慣2 -->
		<section class="lp__contents is-habit2" id="js_habit2">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/habit02.png" alt="朝の習慣2"></span>
				<p>頭痛ーるアプリで<br>天気予報チェック</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__text lp__contents--text">天気予報で日ざしがあるか確認して、日の出時刻を確認する事で効果的に朝の日ざしを浴びる事ができます。</p>

			<!-- アプリの天気予報 -->
			<section class="lp__app is-weather-forecast" id="js_weatherForecast">
				<h4 class="lp__app--title"><em>頭痛ーるアプリの天気予報</em></h4>
				<p class="lp__text lp__app--text">頭痛ーるアプリは天気に加えて、日の出、日の入り時間まで確認する事ができますので朝の生活習慣の手助けになります。</p>
				<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img01.png" alt="天気予報イメージ"></p>
				<p class="lp__hiroshi lp__app--comment"><span>日の出の時間と朝の天気を<br>チェックじゃ！</span></p>
				<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
				<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
			</section>
			<!-- /アプリの天気予報 -->
		</section>
		<!-- /朝の習慣2 -->

		<!-- 朝の習慣3 -->
		<section class="lp__contents is-habit3" id="js_habit3">
			<h3 class="lp__contents--title">
				<span><img src="~@/assets/img/lp_winterhealthcare/habit03.png" alt="朝の習慣3"></span>
				<p>ウォーキングする<br>（朝散歩）</p>
				<i class="lp__line" />
			</h3>
			<p class="lp__contents--img is-small"><img src="~@/assets/img/lp_winterhealthcare/habit_img03.png" alt="ウォーキングイメージ"></p>
			<p class="lp__text lp__contents--text">朝は出来るだけ決まった時間に起きて、<em>適度なウォーキングなど軽い運動</em>をすることが有効です。<em>朝の太陽光は気分を高め、体内時計も正常化</em>してくれますので、朝の散歩が、<em>心の安定と夜の快眠</em>にも繋がるのです。1日3食しっかり食べて、リズムの良い生活習慣は、自律神経を整え、うつや不眠の予防だけでなく、頭痛対策にもつながります。</p>
			<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">お散歩やお出かけ前にチェック！</span><span class="is-large">頭痛ーるアプリの天気予報</span></a>
			<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">お散歩やお出かけ前にチェック！</span><span class="is-large">頭痛ーるアプリの天気予報</span></a>
			<p class="lp__text lp__contents--text">日焼けしたくないから散歩はちょっと...という方。ご安心下さい！<em>紫外線は、11月から2月にかけては、晴れても朝夕は弱いレベル</em>です。<em>晩秋から冬の朝は、日焼けやお肌への影響をほとんど気にせずにお散歩</em>しやすい時期でもあります。</p>
			<p class="lp__contents--img"><img src="~@/assets/img/lp_winterhealthcare/habit_img04.png" alt="紫外線の強さ"></p>

			<!-- アプリの記録機能 -->
			<section class="lp__app is-recording-function" id="js_recordingFunction">
				<h4 class="lp__app--title"><em>頭痛ーるアプリの記録機能</em></h4>
				<p class="lp__text lp__app--text">頭痛ーるアプリば頭痛の痛みだけでなく、体調や飲んだ薬をアプリで記録でき、体調管理に役立てる事ができます。</p>
				<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img02.png" alt="記録イメージ"></p>
				<p class="lp__hiroshi is-tight lp__app--comment"><span>自分が不調になりやすいタイミングが分かりそうじゃ！</span></p>
				<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
				<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
				<p class="lp__maro is-3line lp__contents--comment"><span>自分の状態に心配な点があれば、まずはストレスチェックだニャ！</span></p>
				<a class="lp__button lp__stresscheck--button" href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stresscheckDiagnosis` } })">
					<img src="~@/assets/img/lp_winterhealthcare/stresscheck_banner.png" alt="1分で診断! 医師監修 今の自分がわかるストレスチェック">
				</a>
				<p class="lp__stresscheck--annotation"><small>監修:アイさくらクリニック 木村昌幹 先生</small></p>
			</section>
			<!-- /アプリの記録機能 -->
		</section>
		<!-- /朝の習慣3 -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title">太陽の光を味方に<br>”with頭痛ーるアプリ”で<br>ココロも体も健康な冬に</h2>
			<div class="lp__outro--img"><img src="@/assets/img/lp_winterhealthcare/outro.png" alt="マロと雪だるま"></div>
			<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022top' } })"><span>冬のココロの健康管理</span><span class="is-margin-top4">TOPへ</span></a>
			<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="lp__outro--button is-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button is-back">TOPにもどる</a>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022習慣リンクAndroid" data-gtm-event-act-label="trackingLpWinterHelthcare2022habitBtn_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022習慣リンクiOS" data-gtm-event-act-label="trackingLpWinterHelthcare2022habitBtn_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpWinterHealthcare2022habit',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			$html: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		// TODO: Webview、スゴ得でリポジトリをわける際、common.scssでルートフォントをvwで指定するようにしたい。もしできたらこちらの処理は不要なので削除。
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}
	}
}
</script>

<style lang="scss" scoped>
// 特集共通SASS
@import "~@/assets/sass/lp_winterhealthcare/lp_common";

.lp {
	&__intro {
		&--title { margin: 0 auto; }

		&--text {
			width: 81.8667%;
			margin: $spacing-10 auto 0;
		}
	}

	&__contents {
		$parent: &;

		&--title {
			line-height: $line-height-1;

			& > span { width: 31.7003%; }
			& > p { margin-top: $spacing-16; }
		}

		&--img.is-small { width: 72.0462%; }

		&.is-habit1 {
			padding-bottom: $spacing-25;

			#{$parent}--img:not(.is-small) { margin-top: $spacing-14; }
		}

		&.is-habit2 {
			padding-bottom: $spacing-20;

			#{$parent}--button { margin-top: $spacing-12; }
		}

		&.is-habit3 {
			padding-bottom: $spacing-20;

			& > .lp__app--button { margin-top: $spacing-16; }

			#{$parent}--img:not(.is-small) { margin-top: $spacing-14; }
		}
	}

	&__line { margin-top: $spacing-16; }

	&__app {
		&.is-weather-forecast { margin-top: $spacing-25; }

		&.is-recording-function { margin-top: $spacing-20; }
	}

	&__stresscheck {
		&--button {
			width: 86.4554%;
			margin: $spacing-10 auto 0;
		}

		&--annotation { margin: $spacing-8 0 0; }
	}

	&__outro {
		.lp__contents--button {
			width: 80%;
			margin-top: $spacing-20;
		}

		&--button.is-pagetop { margin-top: $spacing-24; }
	}
}
</style>
